@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
/*@tailwind base;
@tailwind components;
@tailwind utilities;*/
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: CocoSharp;
  src: url(./Coco-Sharp-Regular-trial.ttf);
  src: local("Coco-Sharp-Regular-trial"),
    url(./Coco-Sharp-Regular-trial.ttf) format("truetype");
}

body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: #ffffff;
  color: #000000;
  font-size: 1rem;
  font-family: CocoSharp;
  line-height: 1.5rem;
  font-weight: 400;
  text-rendering: optimizelegibility;
}

.hover\:bg-josh-orange:hover {
  background-color: #ff2e00;
}

.focus\:bg-josh-orange:focus {
  background-color: #ff2e00;
}

.bg-josh-orange {
  background-color: #ff2e00;
}

.bg-josh-gray {
  background-color: #e7e6e6;
}

.bg-josh-blue {
  color: #60dafb;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

h1 {
  background-color: #000000;
  background-image: #000000;

  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.h1-orange {
  background-color: #a8a8a8;
  background-image: #a8a8a8;

  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-bar-text {
  font-size: 2.25rem;
  line-height: 1.75rem;
  font-weight: bolder;
}

.orange-text {
  color: #ff2e00;
}

h3 {
  font-size: 1.4rem !important;
  background-color: #000000;
  background-image: #000000;

  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

img {
  display: block;
  width: 100%;
  height: 100%;
}

input {
  height: 40px;
}

.gradient:focus {
  border: 1px solid transparent;
  background-image: #000000;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

input,
textarea {
  background: #121212c5;
  border-radius: 0.3rem;
  border: 1px solid transparent;
}

*:focus:not(.focus-visible) {
  outline: none;
}

/* Loader */

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
